// const Sentry = require("@sentry/browser");
// const Integrations = require("@sentry/tracing");
const ReactDOM = require("react-dom");
const loadableReady = require("@loadable/component").loadableReady;
/* We use loadable for code splitting. loadableReady is kind of like an Observable.
It triggers a callback when all components on page initialised using loadable
have their chunks downloaded and parsed- ready for execution. */

exports.replaceHydrateFunction = () => {
	/* Docs about Hydration in Gatsby: https://www.gatsbyjs.com/docs/conceptual/react-hydration/
	Usage of replaceHydrateFunction with Loadable: https://www.gatsbyjs.com/plugins/gatsby-plugin-loadable-components-ssr/#my-gatsby-browserjs-already-implements-replacehydratefunction-api
	This code block instructs our Gatsby app to start the hydrating process after all loadable components are ready.
	*/
	return (element, container, callback) => {
		loadableReady(() => {
			ReactDOM.render(element, container, callback);
		});
	};
};

exports.onInitialClientRender = () => {
	console.log("ReactDOM.render has executed : onInitialClientRender");
	let removeCustomJsHideStyle = function () {
		console.info("removing custom body style tag");
		let a = document.getElementById("custom_js_hide");
		if (a) a.parentNode.removeChild(a);
	};
	try {
		if (window.__customJsCode) {
			console.info("executing custom js code");
			window.__customJsCode.init();
		}
		removeCustomJsHideStyle();
	} catch (error) {
		console.error("error while executing custom js code", error);
		removeCustomJsHideStyle();
	}

	// for preview static builds we need capture all elements with className lozad.
	if (window.lozad) {
		const observer = window.lozad(); // lazy loads elements with default selector as '.lozad'
		observer.observe();
	}
	//GrowthInfra : Remove the below because corresponding implentation done by Amit

	// if(process.env.GATSBY_BUSINESS_VERTICAL == "b2u"){
	//   //GrowthInfra : Verify how segment is initialised in b2c
	//   injectSegmentScript();
	// }
};

// exports.onClientEntry = async () => {
//   //GrowthInfra: leave as is now, we will take it up later.
//   if(process.env.GATSBY_ENVIRONMENT === "production" && process.env.GATSBY_BUSINESS_VERTICAL == "b2c"){ //GrowthInfra : Remove this check if sentry is to be used in b2u as well, and when b2u sentry setup is done
//     require("babel-polyfill");
//     const release = await import('./gitRev').then((gitRev)=> gitRev.default);
//     Sentry.init({
//       dsn: process.env.GATSBY_SENTRY_DSN,
//       environment: process.env.PRODUCTION ? "production" : "dev",
//       release: release,
//       integrations: [new Integrations.BrowserTracing()],
//       attachStacktrace: true,
//       sampleRate: 0.01,
//     });
//     window.Sentry = Sentry;
//   }
// };
